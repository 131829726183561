import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        children:[
            {
                path: '/',
                component: () => import('../views/Home/challangeList')
            },{
                path: '/Home/details/:id',
                component: () => import('../views/Home/challangeDetail')
            },{
                path: '/Home/details/:id/:higerUser',
                component: () => import('../views/Home/challangeDetail')
            },{
                path: '/Home/order/:id/:orderLogUser/:higerUser/:orderNo',
                component: () => import('../views/Home/challangeDetail')
            },{
                path: '/Home/video/:id',
                component: () => import('../views/Home/video')
            },
            {
                path: '/Home/ranking/:actId',
                component: () => import('../views/Home/ranking')
            },{
                path: '/Home/invitation/:joinId',
                component: () => import('../views/Home/invitation')
            }
        ]
    },
    {
        path: '/mine',
        name: 'mine',
        component: () => import('../views/Mine.vue'),
        children:[
            {
                path: '/',
                component: () => import('../views/Mine/handleList')
            },{
                path: '/Mine/userInfo',
                component: () => import('../views/Mine/userInfo')
            },{
                path: '/Mine/modHeader',
                component: () => import('../views/Mine/modHeader')
            },{
                path: '/Mine/myAct',
                component: () => import('../views/Home/myChallangeList')
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/Register.vue')
    }
]

const router = new VueRouter({
    routes
})

export default router
