import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        HEAD_TITLE:JSON.parse(sessionStorage.getItem("session_head_title")) ||  '活動'
    },
    getters: {
        HEAD_TITLE:state => state.HEAD_TITLE
    },
    mutations: {
        setHead(state, msg) {
            state.HEAD_TITLE = msg
            sessionStorage.setItem("session_head_title", JSON.stringify(msg))
        }
    },
    actions: {},
    modules: {}
})
