<template>
    <div class="footer-father">
        <div @click="goUrl('/')">
            <div class="iconfont icon-a-jiangbei3x" :class="{checked:this.page=='/'}"></div>
            <div class="title" :class="{checked:this.page=='/'}">活動</div>
        </div>
        <div @click="goUrl('/mine')">
            <div class="iconfont icon-wodemian" :class="{checked:this.page=='/mine'}"></div>
            <div class="title" :class="{checked:this.page=='/mine'}">我的</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer",
    props:['page'],
    methods:{
        goUrl(path){
            if(this.$route.path!=path){
                if(path=='/'){
                    this.$store.commit("setHead",'活動')
                }else{
                    this.$store.commit("setHead",'我的')
                }
                this.$router.push(path)
            }
        }
    },
}
</script>

<style scoped>
    .footer-father{
        display: flex;
        align-items: center;
    }
    .footer-father div{
        flex: 1;
        text-align: center;
    }
    .iconfont{
        font-size: 22px !important;
    }
    .checked{
        color: #FD6147;
    }
    .title{
        font-size: 15px;
    }
</style>