import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//配置element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
Vue.use(ElementUI);

//配置iconfont
import './assets/iconfont/iconfont.css'
//配置google登录
// import GAuth from 'vue-google-oauth2'
//
// const gauthOption = {
//   clientId: '899686050776-ubdk8r8ito4fknd9v3ki6b4gjq9nerma.apps.googleusercontent.com',
//   scope: 'profile email',
//   prompt: 'http://lei-ting.vip:8080'
// }
// Vue.use(GAuth, gauthOption)
import GSignInButton from 'vue-google-signin-button'
Vue.use(GSignInButton);

//配置axios
import axios from 'axios'

//Vue.use(axios);
//axios.defaults.baseURL = 'https://example.com/api'


axios.defaults.baseURL = '/api';
axios.defaults.proxy = {
 host: 'https://lei-ting.vip',
 //host:'http://localhost',
  port: 8080,
  protocol: 'http'
};

Vue.prototype.$axios = axios
//Vue.prototype.$globalVar = 'http://localhost:8080';
Vue.prototype.$globalVar = 'https://lei-ting.vip:8080';


// 配置axios前置拦截器，作用是让所有axios请求携带token，后台需要token校验是否登录
// axios.interceptors.request.use(config => {
//   // 1.从缓存中获取到token,这里的Authorization时登录时你给用户设置token的键值
//   let authorization = sessionStorage.getItem("token")// localStorage.getItem("Authorization");
//   // 2.如果token不为null，那么设置到请求头中，此处哪怕为null，我们也不进行处理，因为后台会进行拦截
//    console.info("进来了")
//   if (authorization) {
//     console.info("拿到了")
//   //后台给登录用户设置的token的键时什么，headers['''']里的键也应该保持一致
//     config.headers['Authorization'] = authorization;
//   }
//   // 3.放行
//   return config;
// }, error => {
// //失败后抛出错误
//   Promise.reject(error);
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


