<template>
    <el-container class='home-container'>
        
        <el-header height="7vh" class="el-col-lg-6">
            <div class="head-area">
               
                <div class="title">{{ $store.state.HEAD_TITLE }}</div>
            </div>
        </el-header>
           
            <el-main   class="el-col-lg-6" >
                <router-view/>
            </el-main>
            
        <el-footer height="none" class="el-col-lg-6">
            <Footer page="/"></Footer>
        </el-footer>
    </el-container>
</template>

<script>
import Footer from "@/components/Footer";
export default {
    name: 'Home',
    components: {Footer},
    data() {
        return {

        };
    },
    methods:{
        goUrl(path){
            this.$router.push(path)
        }
    }
}
</script>
<style>
    .el-main {
    overflow-y: auto;
    height: calc( 7vh - 100px); /* 假设您的header和footer各占100px的高度 */
    }
    .el-container {
    min-height: 100vh;

    }
    .back{
        width: 10px;
        border: 1px solid red;
        
    }
    .title{
        flex: 1;
        text-align: center;
        
    }


    .backto{
        height: 30px;
        width: 72px;
        font-size:13px;
        line-height: 30px;
        color: white;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        position: fixed;
        /* left:0; */
        border:none;
          background: #474141;
        top: 7vh;
    }

 @media screen and (min-width: 1600px) {
    .el-col-lg-6{
      width: 25%;
      margin: auto;
    }
    .backto{
        left: 37.5%;
    }
  }
  @media screen and (min-width: 800px) and (max-width: 1600px){
    .el-col-lg-6{
      width: 40%;
      margin: auto;
    }
     .backto{
        left: 30%;
    }
  }

    @media screen and (min-width: 600px) and (max-width: 800px) {
    .el-col-lg-6{
      width: 60%;
      margin: auto;
    }
     .backto{
        left: 20%;
    }
  }
      @media screen and (min-width: 0px) and (max-width: 600px) {
    .backto{
        left: 0;
    }
      }
  .el-container {
     height: 100%; 
     background: #EDEDED;
    }
</style>

}


</style>
